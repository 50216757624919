import React from 'react';

import { Button, Flex, Box, Spacer } from '@chakra-ui/react';
import { GiNextButton, GiPreviousButton } from "react-icons/gi";
import Totalquantity from './Totalquantity';

const Datatablepagination = ({ page, totalData, perpage, handelpagechange, Loading ,countlabel}) => {

    const PageGenerator = ({ value }) => {
        return (
            <Button p='0px' size='sm' fontSize='12px' border='1px solid gray' borderRadius='3px' onClick={() => handelpagechange(value)}>
                {value}
            </Button>
        )
    }

    return (
        <Box transition='ease-in-out'>{
            Math.ceil(totalData / perpage) > 1 ? <Flex mt='20px' width='100%' gap='10px' flexWrap='wrap'>
                <Flex justifyContent='left' alignItems='center' gap='2px'>
                    {Math.ceil(totalData / perpage) > 3 ?
                        <Button size='sm' border='1px solid gray' p='0px' borderRadius='3px' onClick={() => handelpagechange(1)}>1</Button>
                        : ''}
                    <Button
                        size='sm'
                        p='0px'
                        border='1px solid gray' borderRadius='3px'
                        disabled={page === 1 || Loading}
                        onClick={() => handelpagechange(page - 1)}
                        leftIcon={<GiPreviousButton />}
                    ></Button>

                    {
                        page - 3 > 0 ? <PageGenerator value={page - 3} /> : ''
                    }
                    {
                        page - 2 > 0 ? <PageGenerator value={page - 2} /> : ''
                    }

                    {
                        page - 1 > 0 ? <PageGenerator value={page - 1} /> : ''
                    }
                    <Button size='sm'  color='gray' borderRadius='3px' bg='teal' color='white' >{page}</Button>

                    {
                        page + 1 <= (Math.ceil(totalData / perpage)) ? <PageGenerator value={page + 1} /> : ''
                    }
                    {
                        page + 2 < (Math.ceil(totalData / perpage)) ? <PageGenerator value={page + 2} /> : ''
                    }
                    {
                        page + 3 < (Math.ceil(totalData / perpage)) ? <PageGenerator value={page + 3} /> : ''
                    }

                    <Button
                        size='sm'
                        p='0px'
                        border='1px solid gray' borderRadius='3px'
                        disabled={page >= (Math.ceil(totalData / perpage)) || Loading}
                        onClick={() => handelpagechange(page + 1)}
                        rightIcon={<GiNextButton />}
                    ></Button>
                    {
                        Math.ceil(totalData / perpage) > 3 ?
                            <Button size='sm' p='0' border='1px solid gray' borderRadius='3px' onClick={() => handelpagechange(Math.ceil(totalData / perpage))}>{Math.ceil(totalData / perpage)}</Button> : ''
                    }
                </Flex>
                <Spacer/>
                <Totalquantity totalData={totalData} countlabel={countlabel} />
            </Flex> : <Flex justifyContent='right'>
                <Totalquantity totalData={totalData}  countlabel={countlabel}/>
            </Flex>
        }</Box>
    )
}

export default Datatablepagination