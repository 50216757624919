import React from "react";

// Chakra imports
import { Flex,Image,Text,Badge } from "@chakra-ui/react";
import logo from 'assets/img/logo-white.png';
import { Link } from "react-router-dom";

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode

  let {role} = JSON.parse(localStorage.getItem('user'))||{}

  return (
    <Flex align='center' direction='column' position='sticky'  w='100%' m='auto'>
      <Link to="/admin/dashboard" >
        <Image  w='70%' mx="auto" mt='5px' mb='5px' src={logo} alt='product_img' />
      </Link>
      <HSeparator mb='5px' mt='5px' />
      <Badge p='3px' mt='5px' variant='solid' colorScheme='yellow'>{role && role.split("_").join(' ')}</Badge>
    </Flex>
  );
}

export default SidebarBrand;
