import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { fetch_TestReports } from '../request';
import ReportTable from '../reporttable_componunt/GeneralDataTable';
import { ExportExcel_Report_Test, TestReportExportConsent } from '../request';
import BreadCrumb from 'components/navbar/Breadcrumb';

function toTitleCase(str) {
    if (!str || typeof str !== 'string' || str.trim() === '') {
        return '-';
    }
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}
const TestReports = () => {

    //Doctor name, Doctor Code, Doctor speciality, patient age, patient gender, camp date, camp time, test score, interpretations, scale-name, MR-Name, MR-employee code, HQ, region, Zone

    const truncateText = (text, maxLength) => {
        if (!text) return '-';
        if (text.length <= maxLength) return text;
        return text.substr(0, maxLength) + '...';
    };

    const column = [
        {
            name: <Text as='b'>Test Created At</Text>,
            selector: row => row?.test_created_format || '-',
            sortable: true,
            sortField: 'test_created_format',
         
        },
        {
            name: <Text as='b'>Doctor Name</Text>,
            selector: row => toTitleCase(row?.doc_name) || '-',
            sortable: true,
            sortField: 'doc_name',
         

        },
        {
            name: <Text as='b'>Doctor Code</Text>,
            selector: row => row?.doc_code || '-',
            sortable: true,
            sortField: 'doc_code',
           

        },
        {
            name: <Text as='b'>Doctor Speciality</Text>,
            selector: row => toTitleCase(row?.doc_speciality) || '-',
            sortable: true,
            sortField: 'doc_speciality',
         

        },
        {
            name: <Text as='b'>Patient Age</Text>,
            selector: row => row?.pat_age || '-',
            sortable: true,
            sortField: 'pat_age',
      

        },
        {
            name: <Text as='b'>Patient Gender</Text>,
            selector: row => row?.pat_gender || '-',
            sortable: true,
            sortField: 'pat_gender',
          
        },
        {
            name: <Text as='b'>Camp Date</Text>,
            selector: row => row?.camp_date_format || '-',
            sortable: true,
            sortField: 'camp_date_format',
           
        },
        {
            name: <Text as='b'>Camp Time</Text>,
            selector: row => row?.camp_time || '-',
            sortable: true,
            sortField: 'camp_time',
           
        },
       
        {
            name: <Text as='b'>Test Score</Text>,
            selector: row => row?.test_score || '-',
            sortable: true,
            sortField: 'test_score',
           
        },
        {
            name: <Text as='b' w='500px' >Interpretation</Text>,
            selector: row => truncateText(row?.interpretation, 50) || '-',
            sortable: true,
            wrap: true,
            sortField: 'interpretation',
           
        },
        {
            name: <Text as='b'>Scale</Text>,
            selector: row => row?.scale_id || '-',
            sortable: true,
            sortField: 'scale_id',
           
        },
        {
            name: <Text as='b'>MR Name</Text>,
            selector: row => toTitleCase(row?.name) || '-',
            sortable: true,
            sortField: 'name',
        
        },
        {
            name: <Text as='b'>MR Code</Text>,
            selector: row => row?.mr_code || '-',
            sortable: true,
            sortField: 'mr_code', 
           
        },
        {
            name: <Text as='b'>HQ</Text>,
            selector: row => toTitleCase(row?.hq) || '-',
            sortable: true,
            sortField: 'hq',
           
        },
        {
            name: <Text as='b'>Region</Text>,
            selector: row => toTitleCase(row?.region) || '-',
            sortable: true,
            sortField: 'region',
           
        },
        {
            name: <Text as='b'>Zone</Text>,
            selector: row => toTitleCase(row?.zone) || '-',
            sortable: true,
            sortField: 'zone',
           
        },
    ];

    return (
        <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }}>

            <Box display={{ base: 'block', md: 'none' }} p='5px'>
                <BreadCrumb />
            </Box>

            <ReportTable
                header={'Test Reports'}
                column={column}
                fetchfun={fetch_TestReports}
                exportFunction={ExportExcel_Report_Test}
                exportConsent={TestReportExportConsent}
                countlabel={'Tests'}
                sortablecolm='doc_name'
                defaultscale = ''
            />
        </Box>
    )
}

export default TestReports