// chakra imports
import { Flex, Stack } from "@chakra-ui/react";

// Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import React from "react";
import UserCard from "./UserCard";

// FUNCTIONS
function SidebarContent({ routes, minvalue, onClose }) {

  // SIDEBAR
  return (
    <Flex
    position='relative'
      direction='column'
      // px="16px"
      borderRadius='30px'
      gap='15px'
      height='100vh'
      overflow='scroll'
      css={{
        /* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
        "&::-webkit-scrollbar": {
          display: "none",
        },
        /* Hide scrollbar for Firefox */
        "&": {
          scrollbarWidth: "none",
        },
      }}
    >
      {/* Logo */}
      <Brand minvalue={minvalue} />

      <Stack direction='column' mb='auto' m='auto' w='100%' pl='20px'>
        <Links routes={routes} minvalue={minvalue} onClose={onClose} />
      </Stack>

      {/* <SidebarCard minvalue={minvalue} /> */}
      <UserCard />
    </Flex>
  );
}

export default SidebarContent;
