// Chakra Imports
import { Box, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import BreadCrumb from './Breadcrumb';

export default function AdminNavbar(props) {
	const { secondary, message } = props;

	return (
		<Box
			position={'fixed'}
			bg='white'
			transitionDelay='0s, 0s, 0s, 0s'
			transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
			transition-property='box-shadow, background-color, filter, border'
			transitionTimingFunction='linear, linear, linear, linear'
			alignItems={{ xl: 'center' }}
			display={secondary ? 'block' : 'flex'}
			minH={{ base: 0, md: '70px' }}
			justifyContent={{ xl: 'center' }}
			mx='auto'
			px='10px'
			w={{
				base: 'calc(100vw - 0%)',
				md: 'calc(100vw - 0%)',
				// lg: 'calc(100vw - 0%)',
				xl: 'calc(100vw - 18%)',
				// '2xl': 'calc(100vw - 20%)'
			}}
			boxShadow='base'
			zIndex='99'>
			<Flex
				w='97%'
				p='3px'
				alignItems='center'
				flexDirection={{
					sm: 'column',
					md: 'row'
				}}
			>
				<Box display={{ base: 'none', xl: 'block' }}>
					<BreadCrumb />
				</Box>

				<Box ms='auto' w={{ sm: '100%', xl: 'unset' }}>
					<AdminNavbarLinks
						onOpen={props.onOpen}
						logoText={props.logoText}
						secondary={props.secondary}
						fixed={props.fixed}
					/>
				</Box>
			</Flex>
			{secondary ? <Text color='white'>{message}</Text> : null}
		</Box>
	);
}

AdminNavbar.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func
};
