import React from 'react';
import {
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem, Text, Flex
} from '@chakra-ui/react';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DefinedRange, DateRange } from 'react-date-range';
import { BsCalendar2Week } from "react-icons/bs";

const Datepicker = ({ start_date, to_date, handeldate, handelDatefilter, date }) => {

    return (
        <Box >
            <Menu>
                <MenuButton fontSize='12px' fontWeight='bold' mt='10px' p='10px' pl='10px' pr='10px' w='220px' color='white' borderRadius='5px' bg='teal'>
                    {start_date === '' || to_date === '' ? <Text>Select Date</Text> : <Flex gap='10px' align="center">  <BsCalendar2Week fontSize={'20px'} />  <Text>{start_date} To {to_date}</Text></Flex>}
                </MenuButton>

                <MenuList>
                    {/* <Box>
                        <DateRangePicker
                            onChange={item => handeldate([item.selection])}
                            ranges={date}
                        />
                    </Box> */}
                    <Flex direction={{ base: 'column', md: 'row' }} >
                        <DefinedRange
                            onChange={item => handeldate([item.selection])}
                            ranges={date}
                        />
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => handeldate([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={date}
                        />
                    </Flex>

                    <MenuItem bg='teal.600' color='white' w='fit-content' ml='10px' onClick={handelDatefilter}   _hover={{ color: 'black' }}>
                        Apply
                    </MenuItem>
                </MenuList>

            </Menu>
        </Box>
    )
}

export default Datepicker