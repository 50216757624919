import React from 'react';
import './Loading.css'
import { Flex,Text, Box} from '@chakra-ui/react';
const LoadingComp = () => {
  return (
    <Flex m='auto' w='100%' justifyContent='center' alignItems='center' h='390px' bg='gray.100'>
    <Box className="lds-ripple"><Box></Box><Box></Box></Box>
   </Flex>
  )
}

export default LoadingComp