import React from 'react';
import { defaulter_report, Defaulter_report_export } from '../../request/admin_request.js';
import { Box, Text } from '@chakra-ui/react';
import ReportTable from '../reporttable_componunt/GeneralDataTable';
import BreadCrumb from 'components/navbar/Breadcrumb';

function toTitleCase(str) {
    if (!str || typeof str !== 'string' || str.trim() === '') {
        return '-';
    }
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}

const DefaultReport = () => {

    const column = [
        // {
        //     name: <Text as='b'>ID</Text>,
        //     selector: row => row?.id,
        //     sortable: true,
        //     cell: row => (
        //         <Text style={{ textAlign: 'center' }}>
        //             {row?.id || '-'}
        //         </Text>
        //     ),
        // },
        {
            name: <Text as='b'>Name</Text>,
            selector: row => row?.name,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {toTitleCase(row?.name) || '-'}
                </Text>
            ),
            sortField: 'name',
        },
        // {
        //     name: <Text as='b' >Division</Text>,
        //     selector: row => row?.division,
        //     sortable: true,
        //     cell: row => (
        //         <Text style={{ textAlign: 'center' }}>
        //             {row?.division || '-'}
        //         </Text>
        //     ),
        // },
        {
            name: <Text as='b'>Employee code</Text>,
            selector: row => row?.emp_code,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {row?.emp_code || '-'}
                </Text>
            ),
            sortField: 'emp_code',
        },
        {
            name: <Text as='b'>Designation</Text>,
            selector: row => row?.designation,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {row?.designation || '-'}
                </Text>
            ),
            sortField: 'designation',
        },
        {
            name: <Text as='b'>HQ</Text>,
            selector: row => row?.hq,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {toTitleCase(row?.hq) || '-'}
                </Text>
            ),
            sortField: 'hq',
        },
        {
            name: <Text as='b'>Region</Text>,
            selector: row => row?.region,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {toTitleCase(row?.region) || '-'}
                </Text>
            ),
            sortField: 'region',
        },
        {
            name: <Text as='b'>Zone</Text>,
            selector: row => row?.zone
            ,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {toTitleCase(row?.zone) || '-'}
                </Text>
            ),
            sortField: 'zone',
        },
    ];

    return (
        <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }}>

            <Box display={{ base: 'block', md: 'none' }} p='5px'>
                <BreadCrumb />
            </Box>

            <ReportTable
                header={'Defaulter Reports'}
                column={column}
                fetchfun={defaulter_report}
                exportFunction={Defaulter_report_export}
                // exportConsent={null}
                countlabel={'Employees'}
                sortablecolm='name'
                showscale={false}
            />
        </Box>
    )
}

export default DefaultReport