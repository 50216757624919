import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, useColorModeValue, Flex, Text } from '@chakra-ui/react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { BiChevronRight } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';


function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}

const BreadCrumb = () => {
    const breadcrumbs = useBreadcrumbs();
    const secondaryText = useColorModeValue('gray.700', 'white');
    const navigate = useNavigate();

    // Function to process breadcrumb text
    const processBreadcrumbs = (text) => {
        let content = typeof text === 'string' ? text : text.props.children;
        content = content.replace(/Report\b/, 'Reports');
        content = toTitleCase(content);
        return content.replace(/\d+/g, '').trim();
        
    };

    // Function to determine if a breadcrumb should be displayed
    const shouldDisplayBreadcrumb = (breadcrumb) => {
        const breadcrumbText = processBreadcrumbs(breadcrumb);
        const hiddenBreadcrumbs = ['Admin']; // Breadcrumbs to exclude
        return !hiddenBreadcrumbs.includes(breadcrumbText);
    };

    // Function to determine if a breadcrumb is non-clickable
    const isNonClickableBreadcrumb = (breadcrumb) => {
        const breadcrumbText = processBreadcrumbs(breadcrumb);
        return breadcrumbText === 'Reports'; // Make "Reports" non-clickable
    };

    // Handle breadcrumb click
    const handleBreadcrumbClick = (path) => {
        if (path && path !== '/not-found') {
            navigate(path);
        }
    };

    return (
        <Flex mt='10px' fontWeight='bold'>
            <Breadcrumb separator={<BiChevronRight color='gray.500' />}>
                {breadcrumbs && breadcrumbs
                    .filter(({ breadcrumb }) => shouldDisplayBreadcrumb(breadcrumb)) // Filter out unwanted breadcrumbs
                    .map(({ breadcrumb, match }, i) => {
                        if (!breadcrumb) return null;
                        const breadcrumbText = processBreadcrumbs(breadcrumb);

                        return (
                            <BreadcrumbItem key={i} color={secondaryText} fontSize='13px'>
                                {isNonClickableBreadcrumb(breadcrumb) ? (
                                    <Text color={secondaryText}>
                                        {breadcrumbText}
                                    </Text>
                                ) : (
                                    <BreadcrumbLink
                                        color={secondaryText}
                                        onClick={() => handleBreadcrumbClick(match.pathname)}
                                        _hover={{ textDecoration: 'underline' }}
                                    >
                                        {breadcrumbText}
                                    </BreadcrumbLink>
                                )}
                            </BreadcrumbItem>
                        );
                    })}
            </Breadcrumb>
        </Flex>
    );
}

export default BreadCrumb;
