import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Box ,Text} from "@chakra-ui/react";
import { MonthlyGraphCount } from "views/admin/request/admin_request";
import LoadingComp from "components/LodingComp/LoadingComp";

const MonthlyChart = () => {
  const [monthlyChartData, setMonthlyChartData] = useState({});
  const [loading, setLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [campCounts, setCampCounts] = useState([]);
  const [testCounts, setTestCounts] = useState([]);

  const getMonthlyChartData = async () => {
    try {
      let { data } = await MonthlyGraphCount();
      if (data.status === "success") {
        setLabels(data.data.map((item) => item.month));
        setCampCounts(data.data.map((item) => item.camp_count));
        setTestCounts(data.data.map((item) => item.test_count));
      } else {
        // Handle error (e.g., navigate to an error page)
      }
    } catch (err) {
      // Handle error (e.g., navigate to an error page)
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMonthlyChartData();
  }, []);

  const chartOptions = {
    chart: {
      type: "bar",
      height: 500,
      animations: {
        enabled: true,  
        easing: 'easeinout',  
        speed: 1000, 
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    fontFamily: 'Poppins',
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: labels,
      title: {
        text: "Months",
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
      labels: {
        formatter: (value) => value.toLocaleString(),
      },
    },
    tooltip: {
      y: {
        formatter: (value) => value.toLocaleString(),
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      floating: true,
      offsetY: -10,
      offsetX: 20,
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: false 
      }
    },
  };

  const chartSeries = [
    {
      name: "Camp Count",
      data: campCounts,
    },
    {
      name: "Test Count",
      data: testCounts,
    },
  ];

  return (
  
      <Box p="20px" bg="white" mt="40px" borderRadius="md"  shadow='md' >
      <Text mb="10px" fontWeight='bold' fontSize='20px' color='gray.600'>Camp & Test Summary  <small>(Last 3 months)</small></Text>
        {loading ? (
          <LoadingComp />
        ) : (
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={400}
          />
        )}
      </Box>
   
  );
};

export default MonthlyChart;
