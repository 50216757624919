import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import {ChakraProvider} from '@chakra-ui/react';
import theme from 'theme/theme';
import {HashRouter} from "react-router-dom";
import {Authantication} from 'layouts/auth/Authantication';


import AllRoutes from 'views/router/AllRoutes';

ReactDOM.render(
    <HashRouter>
        <ChakraProvider theme={theme}>
            <Authantication>
                    <AllRoutes/>
            </Authantication>
        </ChakraProvider>
    </HashRouter>,
    document.getElementById('root')
);