import React, { useEffect, useState } from 'react';
import { Box, Select,Text } from '@chakra-ui/react';
import { Fetch_for_Scale } from '../../views/admin/reports/request.js';
import { useNavigate } from 'react-router-dom';


const FilterScale = ({ divi,handelScale,header}) => {
    const Navigate = useNavigate();
    let [scaleList, setScaleList] = useState([]);

    const getScaleList = async () => {
        try {
            let data = await Fetch_for_Scale(divi);
            if (data.status === 'success') {
                if(header === 'Camp Reports'){
                    handelScale('all')
                }
                else{
                    handelScale(data.data.scales[0].name)
                }
                setScaleList(data.data.scales);
            }
        } catch (err) {
            Navigate('/auth/sign-in');
        }
    };


    useEffect(() => {
        getScaleList()
    }, [])

    return (
        <Box borderRadius='3px' textAlign='start' p='3px' alignItems='center' gap='5px'>
        <Text  fontSize='12px'>Scale</Text>
        <Select  focusBorderColor='teal' h='30px' w='150px'  onChange={(e) => handelScale(e.target.value)}>
                    { header === 'Camp Reports' ? <option  value={'all'}>All</option> : null}
                {
                    scaleList && scaleList.map((ele, i) => (
                        <option key={i} value={ele.name}>{ele.display_name}</option>
                    ))

                }
            </Select>
        </Box>
    )
}

export default FilterScale