import { Box, useStyleConfig } from "@chakra-ui/react";
function Card(props) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("Card", { variant });

  return (
    <Box p='15px' {...styles} {...rest} borderRadius='8px' shadow='md'  bg='#FFFFFF'>
      {children}
    </Box>
  );
}

export default Card;
