import React, { useEffect, useState } from "react";
import {
    Text,
    Select,
    Box,
    Flex, Spacer,
    useColorModeValue
} from "@chakra-ui/react";
import Search from "components/Search/Search";
import { useNavigate } from "react-router-dom";
import { DeviceList, DeviceTypeandStatus } from "views/admin/request/admin_request";
import Card from "components/card/Card";
import DataTableFrame from "components/datatable/DataTableFrame";
import Datatablepagination from "components/pagination/Datatablepagination";
import { ExportExcel_device } from "views/admin/request/admin_request";
import Divisionfilter from "components/filterComponunts/Divisionfilter";
import LoadingComp from "components/LodingComp/LoadingComp";
import BreadCrumb from "components/navbar/Breadcrumb";
import ExportBtn from "components/LodingComp/ExportBtn";


const Devicelist = () => {
    let [device, setDevice] = useState([]);
    let [devicetype, setDeviceType] = useState([]);
    let [devicestatus, setDeviceStatus] = useState([]);
    let [filterText, setFilterText] = useState('');
    let [loading, setLoading] = useState(false);
    let [dev_id, setDev_id] = useState('');
    let [dev_type, setDev_type] = useState('');
    let [dev_status, setDev_status] = useState('');
    let [page, setPage] = useState(1);
    let [perpage, setPerpage] = useState(1);
    let [totalData, setTotalData] = useState(0);
    let [exportLoad, setExportLoad] = useState(false);

    let { divisions } = JSON.parse(localStorage.getItem('user')) || [];


    let colormode = useColorModeValue('#FFFF', '#111C44');

    let navigate = useNavigate()

    const GetDevice = async () => {
        setLoading(true)
        let res = await DeviceList(dev_id, dev_type, dev_status, page, filterText)
        if (res.status === 'success') {
            setLoading(false);
            setDevice(res.data.data);
            setPerpage(res.data.per_page);
            setTotalData(res.data.total);
            setLoading(false);
        }
    };

    const handelSearch = (val) => {
        setFilterText(val)
    };

    const handelpagechange = (val) => {
        setPage(val)
    }

    const handeldivision = (val) => {
        setDev_id(val)
    };


    const GetDeviceType_Status = async () => {
        let { data, status } = await DeviceTypeandStatus();
        if (status === 'success') {
            setDeviceType(data.device_types)
            setDeviceStatus(data.device_status)
        } else {
            navigate('/auth/sign-in')
        }
    };

    const handelExport = async () => {
        try {
            setExportLoad(true)
            await ExportExcel_device(dev_id, dev_type, dev_status)
            setExportLoad(false)
        } catch (err) {
            console.log(err)
        }
    };

    const column = [
        {
            name: <Text as='b' fontSize='13px'>Inventory_id</Text>,
            selector: row => row.inventory_id,
            sortable: true,
        },
        {
            name: <Text as='b' fontSize='13px'>Division Name</Text>,
            selector: row => row.division_name,
            sortable: true
        },
        {
            name: <Text as='b' fontSize='13px'>Sim Id</Text>,
            selector: row => row.sim_id ?? '--',
            sortable: true,
        },
        {
            name: <Text as='b' fontSize='13px'>Type</Text>,
            selector: row => row.type,
            sortable: true
        },
        {
            name: <Text as='b' fontSize='13px'>Status</Text>,
            selector: row => row.status,
            sortable: true
        },
        {
            name: <Text as='b' fontSize='13px'>Subscription Plans</Text>,
            selector: row => row.subscription_plan,
            sortable: true
        },
        {
            name: <Text as='b' fontSize='13px'>Last Heartbeat</Text>,
            selector: row => row.last_heartbeat_at ?? '--',
            sortable: true
        },
        {
            name: <Text as='b' fontSize='13px'>MR</Text>,
            selector: row => row.lastest_mr ?? '--',
            sortable: true
        }
    ];

    useEffect(() => {
        GetDevice();
    }, [dev_id, dev_status, dev_type, page, filterText])

    useEffect(() => {
        GetDeviceType_Status();
    }, [])

    return (
        <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }} pb={{ base: "15px", md: "40px", xl: "40px" }}>
            <Box display={{ base: 'block', md: 'none' }} p='5px'>
                <BreadCrumb />
            </Box>

            <Card
                direction='column'
                w='100%'
                bg={colormode}
            >
                <Box>
                    <Text as='b' mb='10px' >Device List</Text>
                    {/* Data Table */}

                    {/* Filter-selector */}
                    <Flex gap='10px' w='100%' overflow='auto' alignItems='baseline'>

                        <Divisionfilter
                            Data={divisions}
                            name="Division Name"
                            All={true}
                            handelSelect={handeldivision} />

                        <Box bg='#80D9FF' borderRadius='3px' textAlign='center' p='5px' gap='5px'>
                            <Text as='b' fontSize='12px'>Device Type</Text>
                            <Select h='30px' w='150px' onChange={(e) => setDev_type(e.target.value)}>
                                <option value=''>All</option>
                                {devicetype && devicetype.map((ele) => {
                                    return (
                                        <option key={ele} value={ele}>{ele}</option>
                                    )
                                })}
                            </Select>
                        </Box>

                        <Box bg='#80D9FF' borderRadius='3px' textAlign='center' p='5px' alignItems='center' gap='5px'>
                            <Text as='b' fontSize='12px'>Device Status</Text>
                            <Select h='30px' w='150px' onChange={(e) => setDev_status(e.target.value)}>
                                <option value=''>All</option>
                                {devicestatus && devicestatus.map((ele) => {
                                    return (
                                        <option key={ele} value={ele}>{ele}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Spacer />
                        <Flex justifyContent='center' alignItems='center' gap='10px'>
                            {/* Search */}
                            <Search filterText={filterText} handelSearch={handelSearch} />
                            <ExportBtn handelExport={handelExport} exportLoad={exportLoad} />
                        </Flex>

                    </Flex>

                    {/* Data Table */}
                    <Box>
                        {loading ? <LoadingComp /> : <DataTableFrame data={device} column={column} keyvalue='id' progressPending={loading} />}
                    </Box>
                </Box>

                {/* Pagination */}

                <Datatablepagination page={page} totalData={totalData} perpage={perpage} handelpagechange={handelpagechange} Loading={loading} />


            </Card>
        </Box>
    )
}

export default Devicelist

