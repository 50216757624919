/* eslint-disable */
import React, {useContext} from "react";
import { useNavigate, useLocation, json } from "react-router-dom";
import { AuthContext } from "layouts/auth/Authantication";
// chakra imports
import {
  Box, Flex, HStack, Text, useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";



export function SidebarLinks(props) {

  let { role } = JSON.parse(localStorage.getItem('user')) || '';
  let location = useLocation();
  let Navigate = useNavigate();
  let {campplan} = useContext(AuthContext);
  let activeColor = useColorModeValue("white", "white");
  let inactiveColor = useColorModeValue(
    "secondaryBlack.500",
    "secondaryBlack.500"
  );
  let activeIcon = useColorModeValue("white", "white");
  let textColor = useColorModeValue("secondaryBlack.500", "white");
  let brandColor = useColorModeValue("red.500", "red.400");

  const { routes, minvalue, onClose } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };


  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)

  const NavtigatemeTo = (navpath) => {
    // console.log(navpath,location)
    if (onClose) {
      onClose()
    }
    if (navpath === location.pathname) {
      return
    }
    Navigate(navpath)
  };


  const HandelDisplay = (route) => {
    if (route === 'Profile' || route === 'Employee List' || route === 'Device List') {
      return 'none'
    }
    return 'block'
  }




  const createLinks = (routes) => {

    return routes.map((route) => {

      if (route.category) {
        return (
          <Box key={route.id}>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight='bold'
              mx='auto'
              ps={{
                // sm: "10px",
                // xl: "16px",
              }}
            // pt='18px'
            // pb='12px'
            >
              {route.name}
            </Text>{createLinks(route.items)}
          </Box>
        );
      } else if (
        route.layout === "/admin"
        || route.layout === "/report"
        || route.layout === "/camp_plan"
        // route.layout === "/auth" ||
        // route.layout === "/rtl"
      ) {
        return (
          // Role base rendaring of sidebar routes
          <Box key={route.id} transition='ease-in-out' display={role && role === 'employee' ? HandelDisplay(route.name) : 'block'} cursor='pointer' overflow='hidden' p={0}>
            {route.icon ? (
              <HStack
                spacing={
                  activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                }
                ps='0px'>
                {
                  !route.subrouts ?
                    <Flex
                      w='100%'
                      cursor='pointer'
                      onClick={() => NavtigatemeTo(route.layout + route.path)}
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Box
                        borderRadius='50%'
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeIcon
                            : textColor
                        }
                        me='18px'>{route.icon}</Box>
                      {
                        !minvalue && <Text
                          me='auto'
                          color={
                            activeRoute(route.path.toLowerCase())
                              ? activeColor
                              : textColor
                          }
                          _hover={{ color: 'white' }}
                          fontWeight={
                            activeRoute(route.path.toLowerCase())
                              ? "bold"
                              : "normal"
                          }
                        >
                          {route.name}
                        </Text>
                      }

                    </Flex>
                    :
                     route.layout === '/report' || ( route.layout === '/camp_plan' && campplan) ?
                    <Accordion border='none' defaultIndex={[0]} allowToggle ml='5px' w={{ sm: '100%' }}>
                      <AccordionItem border='none' textAlign='left' p='0' mb='0'>
                        <AccordionButton w='250px' pl='0' p={0} color={textColor} border='none' as='div' _hover={{ bg: 'none' }}>
                          <Flex as="span" textAlign='left' w='100%' alignItems='center'>
                            <Flex gap='2px' alignItems='center'>
                              <AccordionIcon fontSize='30px' />
                              <Text color='textColor' pl='10px'>{route.name}</Text>
                            </Flex>

                          </Flex>

                        </AccordionButton>
                        <AccordionPanel pb='0'>
                          {
                            route.subrouts.map((ele) =>

                              <Flex key={ele.id} cursor='pointer' onClick={() => NavtigatemeTo(route.layout + ele.path)} alignItems='center' justifyContent='center' mt='5px'>
                                <Box
                                  color={
                                    activeRoute(ele.path.toLowerCase())
                                      ? activeIcon
                                      : textColor
                                  }

                                  me='18px'>
                                  {ele.icon}
                                </Box>
                                {
                                  !minvalue && <Text
                                    me='auto'
                                    color={
                                      activeRoute(ele.path.toLowerCase())
                                        ? activeColor
                                        : textColor
                                    }
                                    _hover={{ color: 'white' }}
                                    fontWeight={
                                      activeRoute(ele.path.toLowerCase())
                                        ? "bold"
                                        : "normal"
                                    }
                                  >
                                    {ele.name}
                                  </Text>
                                }

                              </Flex>

                            )
                          }
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>  :
                    null
                }

                <Box
                  h='36px'
                  w='4px'
                  bg={
                    activeRoute(route.path.toLowerCase())
                      ? brandColor
                      : "transparent"
                  }
                  borderRadius='5px'
                ></Box>
              </HStack>
            ) : (
              <HStack
                spacing={
                  activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                }
                // py='5px'
                ps='10px'
              >
                <Text
                  me='auto'
                  color={
                    activeRoute(route.path.toLowerCase())
                      ? activeColor
                      : inactiveColor
                  }
                  fontWeight={
                    activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                  }>
                  {route.name}
                </Text>
                <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' ></Box>
              </HStack>

            )}
          </Box>
          // </NavLink>

        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
};

export default SidebarLinks;
