import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import Divselevctor from './SelectDicision';
import Divisionsinfo from './Divisionsinfo';

const Divisions = ({ divisions }) => {
    let [DivOptn, setDivOption] = useState(divisions[0].division_id);

    const handeloptin = (val) => {
        setDivOption(val)
    };

    return (
        <Flex w='100%' mt='10px' gap='10px' flexDirection={{ base: 'column', md: 'row' }} >
            <Divselevctor divisions={divisions} Div={DivOptn} handeloptin={handeloptin} />
            <Divisionsinfo DivOptn={DivOptn} />
        </Flex>
    )
}

export default Divisions