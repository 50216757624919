import React from "react";
import { Box,Text} from "@chakra-ui/react";
// Custom components
import Banner from "views/admin/profile/components/Banner";
import BreadCrumb from "components/navbar/Breadcrumb";

// Assets
import banner from "assets/img/banner.png";
import Divisions from "./components/divisions/Divisions";


export default function Overview() {
  let { divisions, name, role, profile_photo_path } = JSON.parse(localStorage.getItem('user')) || [];


  return (
    <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }} pb={{ base: "15px", md: "40px", xl: "40px" }}>
      <Box display={{ base: 'block', md: 'none' }} p='5px'>
        <BreadCrumb />
      </Box>

      {/* Main Fields */}
        <Banner
          gridArea='1 / 1 / 2 / 2'
          banner={banner}
          avatar={profile_photo_path}
          name={name}
          job={role}
        />

      <Box mt='20px' p={{base:0,md:'10px'}} bg='white' borderRadius='5px'>
        <Divisions divisions={divisions} />
      </Box>

    </Box>
  );
}
