import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import { fetch_campreports } from '../request';
import BreadCrumb from 'components/navbar/Breadcrumb';
import ReportTable from '../reporttable_componunt/GeneralDataTable';
import { ExportExcel_Report_Camp, CampReportExportConsent } from '../request';

function toTitleCase(str) {
    if (!str || typeof str !== 'string' || str.trim() === '') {
        return '-';
    }
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}

const Campreports = () => {

    const column = [
        {
            name: <Text as='b' textAlign='left'>Camp Date</Text>,
            selector: row => row?.camp_date_format || '-',
            sortable: true,
            sortField: 'camp_date_format',
            minWidth: '120px',
        },
        {
            name: <Text as='b'  textAlign='left'>Camp Time</Text>,
            selector: row => row?.camp_time || '-',
            sortable: true,
            sortField: 'camp_time',
            minWidth: '120px',
        },
        {
            name: <Text as='b'  textAlign='left'>Doctor Name</Text>,
            selector: row => toTitleCase(row?.doc_name) || '-',
            sortable: true,
            sortField: 'doc_name',
            minWidth: '180px',
        },
        {
            name: <Text as='b'  textAlign='left'>Doctor Code</Text>,
            selector: row => row?.doc_code || '-',
            sortable: true,
            sortField: 'doc_code',
            minWidth: '100px',
        },
        {
            name: <Text as='b'  textAlign='left'>Doctor Speciality</Text>,
            selector: row => toTitleCase(row?.doc_speciality) || '-',
            sortable: true,
            sortField: 'doc_speciality',
            minWidth: '150px',
        },
        {
            name: <Text as='b' textAlign='left'>Total Tests</Text>,
            selector: row => row?.total_tests || '-',
            sortable: true,
            sortField: 'total_tests',
            minWidth: '50px',
        },
        {
            name: <Text as='b'  textAlign='left'>Total Patients</Text>,
            selector: row => row?.total_patients || '-',
            sortable: true,
            sortField: 'total_patients',
            minWidth: '80px',
        },
        {
            name: <Text as='b'  textAlign='left'>Scale</Text>,
            selector: row => row?.scale_id || '-',
            sortable: true,
            sortField: 'scale_id',
            minWidth: '150px',
        },
        {
            name: <Text as='b'  textAlign='left'>MR Name</Text>,
            selector: row => toTitleCase(row?.name) || '-',
            sortable: true,
            sortField: 'name',
            minWidth: '180px',
        },
        {
            name: <Text as='b'  textAlign='left'>MR Code</Text>,
            selector: row => row?.mr_code || '-',
            sortable: true,
            sortField: 'mr_code',
        },
        {
            name: <Text as='b'  textAlign='left'>HQ</Text>,
            selector: row => toTitleCase(row?.hq) || '-',
            sortable: true,
            sortField: 'hq',
            minWidth: '150px',
        },
        {
            name: <Text as='b'  textAlign='left'>Region</Text>,
            selector: row => toTitleCase(row?.region) || '-',
            sortable: true,
            sortField: 'region',
            minWidth: '150px',
        },
        {
            name: <Text as='b'  textAlign='left'>Zone</Text>,
            selector: row => toTitleCase(row?.zone) || '-',
            sortable: true,
            sortField: 'zone',
            minWidth: '150px',
        },
    ];



    return (
        <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }}>

            <Box display={{ base: 'block', md: 'none' }} p='5px'>
                <BreadCrumb />
            </Box>

            <ReportTable
                header={'Camp Reports'}
                column={column}
                fetchfun={fetch_campreports}
                exportFunction={ExportExcel_Report_Camp}
                exportConsent={CampReportExportConsent}
                countlabel={'Camps'}
                sortablecolm='doc_name'
            />

        </Box>
    )
}

export default Campreports;