import { Box, Text, ListItem, List, Flex, Icon } from '@chakra-ui/react';
import Card from 'components/card/Card';
import React, { useEffect, useState, useContext } from 'react';
//import { FetchDivision } from 'views/admin/profile/components/divisions/request';
import { FaClipboardList } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { AuthContext } from 'layouts/auth/Authantication';

const ScalesData = () => {
    let { divisions } = JSON.parse(localStorage.getItem('user')) || [];
    const [Scales, setScales] = useState([]);
    let {FetchDivision} = useContext(AuthContext);
    const getData = async () => {
        try {
            let { data: { scales } } = await FetchDivision(divisions[0].division_id);
            setScales(scales);
        } catch (err) {
            console.log({ err });
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const listItemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <Box maxW="100%" mt= {{sm:'10px', md: '30px'}}>
            {Scales.length ? <Text mb="10px" fontWeight='bold' fontSize='24px' mt={{sm:'10px', md: '30px'}} color='gray.600'>Scales</Text> : null}
            <List spacing={3}>
                <Flex wrap="wrap" gap={3}>
                    {Scales.map((scale, index) => (
                        <motion.div
                            key={index}
                            initial="hidden"
                            animate="visible"
                            variants={listItemVariants}
                            transition={{ duration: 0.3, delay: index * 0.1 }}
                        >
                            <ListItem p={3} borderRadius="md" boxShadow="md" bg="#FFFFFF" width="fit-content">
                                <Flex alignItems="center">
                                    <Icon as={FaClipboardList} boxSize={6} mr={4} color="blue.500" />
                                    <Text fontSize="lg" fontWeight="medium">
                                        {scale.display_name}
                                    </Text>
                                </Flex>
                            </ListItem>
                        </motion.div>
                    ))}
                </Flex>
            </List>
        </Box>
    );
};

export default ScalesData;
