import React from 'react';
import { InputGroup, Input, InputRightAddon } from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';

const Search = ({filterText,handelSearch}) => {


    return (
        <InputGroup w='fit-content'>
            <Input
                value={filterText}
                w='200px'
                placeholder='Search ...'
                onChange={(e) => handelSearch(e.target.value)}
                borderRadius='3px'
                focusBorderColor='teal' 
            />
            <InputRightAddon
                children={<Search2Icon color='#fff' />}
                cursor='pointer'
                borderWidth='1px'
                backgroundColor='teal'
                borderRadius='3px'

            />
        </InputGroup>
    )
}

export default Search