import React from 'react';
import { Box } from '@chakra-ui/react';
import DataTable from 'react-data-table-component';

const DataTableFrame = ({ data, column, keyvalue, progressPending,  onSort,
  sortColumn,
  sortDirection }) => {

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px',
        with:'300px'
      },
    },
    headCells: {
      style: {
        with: 'fit-content',
        fontSize: '13px'
      },
    },
    cells: {
      style: {
        fontSize: '13px'
      },
    },
  };


  return (
    <Box textAlign='center' mt='20px'>
      <DataTable
        data={data}
        columns={column}
        keyField={keyvalue}
        // progressPending={progressPending}
        striped={true}
        // responsive={true}
        onSort={onSort} // Handle sort events
        sortServer // Indicate that sorting is handled server-side
        sortColumn={sortColumn} // Controlled sorting column
        sortDirection={sortDirection} // Controlled sorting direction
        customStyles={customStyles}
      />
    </Box>
  )
}

export default DataTableFrame