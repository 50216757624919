import React from 'react';
import {
    Box, Text, Flex
} from '@chakra-ui/react';

const Counts = ({ data }) => {


    return (
        <Box>
            {
                data && data.map((ele, i) => <Flex gap='10px' justifyContent='space-evenly' w='100%' key={i} textAlign='center' mt='10px' p='5px' fontSize='14px'>
                    {/* <Box border='1px dashed gray' w='100%' >
                        <Text as='b' fontSize='2xl'>{ele.devices ?? '---'}</Text><br/>
                        <Text as='b' color='gray'>Devices</Text>
                    </Box> */}

                    <Box border='1px dashed gray'  w='100%' >

                        <Text as='b' fontSize='2xl'>{ele.thermal_printers ?? '---'}</Text><br/>
                        <Text as='b' color='gray'>Thermal Printers</Text>
                    </Box>
                    <Box border='1px dashed gray' w='100%' >
                        <Text as='b' fontSize='2xl'>{ele.rolls ?? '---'}</Text><br/>
                        <Text as='b' color='gray'>Rolls</Text>
                    </Box>
                </Flex>
                )
            }
        </Box>
    )
}

export default Counts