import React from 'react';
import { Box, Text, Flex, VStack, Badge, Avatar, Heading } from '@chakra-ui/react';


const Divselevctor = ({ divisions, handeloptin, Div }) => {

   return (
         <Box w={{ base: '100%', md: '30%' }} h='100%' overflow='auto'>
            <VStack
               spacing='5px'
               align='stretch'
            >

               <Heading as='h3' size='md'>
                  <Badge bg='white'  fontSize='18px' borderRadius='2px' variant='subtle' >
                     Total divisions : {divisions && divisions.length}
                  </Badge>
               </Heading>
               {
                  divisions && divisions.map((ele) => (
                     <Box key={ele.id} borderRadius='5px'  p='5px' bg={Div === ele.division_id ? 'gray.200' : ''}>
                        <Flex
                        border='2px gray'
                           bg={Div === ele.division_id ? 'blue.10' : ''}
                           p='8px'
                           alignItems='center'
                           gap='10px' onClick={() => handeloptin(ele.division_id)}>

                           <Avatar name={ele.division_name} src='logo' />
                           <Text fontWeight='bold'>
                              {ele.division_name}
                           </Text>
                        </Flex>
                     </Box>
                  ))
               }
            </VStack>
         </Box>
   )
}

export default Divselevctor