import React, { useEffect, useState } from 'react';
import {
    Text, Box,
    Flex, Spacer
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { EmployeeList, ExportExcel_employee } from 'views/admin/request/admin_request';
import Card from 'components/card/Card';
import DataTableFrame from "components/datatable/DataTableFrame";
import Datatablepagination from 'components/pagination/Datatablepagination';
import Divisionfilter from 'components/filterComponunts/Divisionfilter';
import Search from 'components/Search/Search';
import LoadingComp from 'components/LodingComp/LoadingComp';
import ExportBtn from 'components/LodingComp/ExportBtn';
import { useDebounce } from 'use-debounce';

function toTitleCase(str) {
    if (!str || typeof str !== 'string' || str.trim() === '') {
        return '-';
    }
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}

const EmploysList = () => {
    let { divisions } = JSON.parse(localStorage.getItem('user')) || [];
    let [empdata, setEmpData] = useState([]);
    let [page, setPage] = useState(1);
    let [perpage, setPerpage] = useState(1);
    let [totalData, setTotalData] = useState(0);
    let [dev_id, setDev_id] = useState(divisions[0].division_id);
    let [filterText, setFilterText] = useState('');
    let [Loading, setLoading] = useState(false);
    let [exportLoad, setExportLoad] = useState(false);
    const [sortColumn, setSortColumn] = useState('name'); // Default sort column
    const [sortDirection, setSortDirection] = useState('asc'); // Default sort direction

    // Use useDebounce to debounce the filterText
    const [debouncedFilterText] = useDebounce(filterText, 500);

    const navigate = useNavigate();

    const getemployee = async (type) => {
        try {
            if (type !== 'sort') {
                setLoading(true);
            }
            let data = await EmployeeList(dev_id, debouncedFilterText, page, sortColumn, sortDirection);
            if (data.status === 'success') {
                setEmpData(data.data.data);
                setTotalData(data.data.total);
                setPerpage(data.data.per_page);
                setLoading(false);
            } else {
                navigate('/auth/sign-in');
            }
        } catch (err) {
            console.log(err);
            navigate('/auth/sign-in');
        }
    };

    const column = [
        {
            name: <Text as='b' fontSize='13px'>Name</Text>,
            selector: row => toTitleCase(row?.name) || '-',
            sortable: true,
            sortField: 'name',
        },
        {
            name: <Text as='b' fontSize='13px'>Designation</Text>,
            selector: row => row?.designation || '-',
            sortable: true,
            sortField: 'designation',
        },
        {
            name: <Text as='b' fontSize='13px'>User Name</Text>,
            selector: row => row?.username || '-',
            sortable: true,
            sortField: 'username',
        },
        {
            name: <Text as='b' fontSize='13px'>Subscription Code</Text>,
            selector: row => row?.subscription_code || '-',
            sortable: true,
            sortField: 'subscription_code',
        },
        {
            name: <Text as='b' fontSize='13px'>Employee Code</Text>,
            selector: row => row?.emp_code || '-',
            sortable: true,
            sortField: 'emp_code',
        },
        {
            name: <Text as='b' fontSize='13px'>Last Login At</Text>,
            selector: row => row?.last_login_At || '--',
            sortable: true,
            sortField: 'last_login_At',
        }
    ];

    const handelSearch = (val) => {
        setFilterText(val);
    };

    const handelpagechange = (val) => {
        setPage(val);
    };

    const handelSelect = (val) => {
        setDev_id(val);
    };

    const handelExport = async () => {
        try {
            setExportLoad(true);
            await ExportExcel_employee(dev_id);
            setExportLoad(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getemployee('regular');
    }, [dev_id, debouncedFilterText, page]);

    useEffect(() => {
        getemployee('sort');
    }, [sortDirection]);

    const handleSort = (column, sortDirection) => {
        setSortColumn(column.sortField);
        setSortDirection(sortDirection);
    };

    return (
        <Card>
            <Flex gap='10px' w='100%' overflow={{ base: 'scroll', md: 'auto' }}>
                <Text fontSize='xl'>Employee List</Text>
                <Divisionfilter
                    Data={divisions}
                    name="Division Name"
                    handelSelect={handelSelect}
                    All={true}
                />
                <Spacer />
                <Flex gap='10px'>
                    <Search filterText={filterText} handelSearch={handelSearch} />
                    <ExportBtn handelExport={handelExport} exportLoad={exportLoad} />
                </Flex>
            </Flex>

            <Box>
                {Loading ? <LoadingComp /> :
                    <DataTableFrame
                        data={empdata}
                        keyvalue='subscription_code'
                        column={column}
                        onSort={handleSort} // Pass the sorting handler
                        sortServer // Tell DataTableFrame that sorting is handled by the server
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        progressPending={Loading}
                    />
                }
            </Box>

            <Datatablepagination page={page} totalData={totalData} perpage={perpage} handelpagechange={handelpagechange} Loading={Loading} countlabel={'Employees'} />
        </Card>
    );
};

export default EmploysList;
