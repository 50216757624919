import React from 'react';

import { Box, Text, Select } from '@chakra-ui/react';

const Divisionfilter = ({ Data, handelSelect, name, All }) => {

    let user = JSON.parse(localStorage.getItem('user')) || {};

    return (
        <Box>
            {
                user.role === 'admin' ?
                    <Box bg='#80D9FF' w='150px' borderRadius='3px' textAlign='start' p='3px' alignItems='center' gap='5px'>
                        <Text as='b' fontSize='12px'>{name}</Text>
                        <Select h='28px' onChange={(e) => handelSelect(e.target.value)}>
                            {All ? <option value=''> All</option> : ''}
                            {Data && Data.map((ele) => {
                                return (
                                    <option key={ele.id} value={ele.division_id}>{ele.division_name}</option>
                                )
                            })}
                        </Select>
                    </Box> :
                    <Box></Box>
            }
        </Box>
    )
}

export default Divisionfilter