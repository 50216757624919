import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Fetch_regionwiseReports,ExportExcel_ReginReport } from '../request';
import ReportTable from '../reporttable_componunt/GeneralDataTable';

function toTitleCase(str) {
    if (!str || typeof str !== 'string' || str.trim() === '') {
        return '-';
    }
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}

const RegionwiseReports = () => {

    const column = [
        {
            name: <Text as='b'>Region</Text>,
            selector: row => toTitleCase(row?.state_code) || '-',
            sortable: true,
            sortField: 'state_code',
        },
        {
            name: <Text as='b'>Camps</Text>,
            selector: row => row?.camps || '-',
            sortable: true,
            sortField: 'camps',
        },
        {
            name: <Text as='b'>Doctors</Text>,
            selector: row => row?.doctors || '-',
            sortable: true,
            sortField: 'doctors',
        },
        {
            name: <Text as='b'>Patients</Text>,
            selector: row => row?.patients || '-',
            sortable: true,
            sortField: 'patients',
        },
        {
            name: <Text as='b'>Tests</Text>,
            selector: row => row?.tests || '-',
            sortable: true,
            sortField: 'tests',
        },
    ];



    return (
        <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }}>

            <ReportTable
                header={'Regionwise Reports'}
                column={column}
                fetchfun={Fetch_regionwiseReports}
                exportFunction={ExportExcel_ReginReport}
                // exportConsent={}
                countlabel={'Regions'}
                sortablecolm='state_code'
                showscale={false}
            />
        </Box>
    )
}

export default RegionwiseReports