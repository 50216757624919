import React, { useEffect, useState, useContext } from 'react';
import {Box, Text, Flex, VStack, Avatar, Heading, Table, Tbody, Tr, Td} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
//import { FetchDivision } from './request';
import LoadingComp from 'components/LodingComp/LoadingComp';
import Counts from './Counts';
import { AuthContext } from 'layouts/auth/Authantication';

const Divisionsinfo = ({ DivOptn }) => {
    let {FetchDivision} = useContext(AuthContext);
    let [Loading, setLoading] = useState(false);
    let [division, setDivision] = useState({});
    let [subscriptnPlan, setSubscriptnplan] = useState({});
    let [scale, setScale] = useState([]);
    let [count, setCount] = useState([
        {
            devices: null,
            thermal_printers: null,
            rolls: null
        }
    ])

    let Navigate = useNavigate();

    const getDivisionData = async () => {
        setLoading(true);
        try {
            let data = await FetchDivision(DivOptn)
            if (data.status === 'success') {
                // console.log(data)
                setDivision(data.data.division);
                setSubscriptnplan(data.data.subscription_plan);
                setScale(data.data.scales);
                setCount([
                    {
                        devices: data.data.devices,
                        thermal_printers: data.data.thermal_printers,
                        rolls: data.data.rolls
                    }
                ])
                setLoading(false);
            }
        } catch (err) {
            Navigate('auth/sign-in')
        }
    }

    useEffect(() => {
        getDivisionData()
    }, [DivOptn])

    return (
        <Box  w={{ base: '100%', md: '70%' }} p='10px' bg='gray.200' borderRadius='5px'>
            <Box h='100%' overflow='auto'>
                {!Loading ?
                    <Box  >
                        <Flex   alignItems='center' gap='10px' m='10px' mb='15px' >
                            <Avatar borderRadius='10%' src={division.logo} alt='Icon' p='3px' />
                            <Heading as='h4' size='lg'>
                                {division.name}
                            </Heading>
                        </Flex>
                        <Flex  gap='10px' w='100%' mt='10px' flexDirection={{ base: 'column', md: 'row' }}>
                            <Box w='100%' textAlign='left'>
                                <Text >Subscription Plan</Text>
                                {
                                    subscriptnPlan ? <Table variant='striped' colorScheme='white' mt='10px' color='blackAlpha.900' px='10px'>
                                        <Tbody fontSize='15px' px='10px'>

                                            <Tr>
                                                <Td p='8px'><Text fontSize='15px' as='b'>Plan Name</Text></Td>
                                                <Td p='8px'>{subscriptnPlan.plan_name && subscriptnPlan.plan_name}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td p='8px'><Text fontSize='15px' as='b'>Formation Date</Text></Td>
                                                <Td p='8px'>{subscriptnPlan.from_date}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td p='8px'><Text fontSize='15px' as='b'>End Date</Text></Td>
                                                <Td p='8px'>{subscriptnPlan.to_date}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td p='8px'><Text fontSize='15px' as='b'>PO Number</Text></Td>
                                                <Td p='8px'>{subscriptnPlan.po_number}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td p='8px'><Text fontSize='15px' as='b'>PO Quantity</Text></Td>
                                                <Td p='8px'>{subscriptnPlan.po_quantity}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td p='8px'><Text fontSize='15px' as='b'>RM Name</Text></Td>
                                                <Td p='8px'>{subscriptnPlan.rm_name}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td p='8px'><Text fontSize='15px' as='b'>RM Email</Text></Td>
                                                <Td p='8px'>{subscriptnPlan.rm_email}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td p='8px'><Text fontSize='15px' as='b'>RM Mobile</Text></Td>
                                                <Td p='8px'>{subscriptnPlan.rm_mobile}</Td>
                                            </Tr>

                                        </Tbody>
                                    </Table> :
                                        <Flex m='auto' justifyContent='center' alignItems='center' h='20%' textAlign='center' borderRadius='3px' p='5px'>
                                            <Text>There are no records to display</Text>
                                        </Flex>
                                }
                                {/* ---------- Count Table -------------------*/}
                                <Counts data={count} />
                            </Box>
                            <Box w='100%' >
                                <Text >Scales</Text>
                                <VStack
                                    h='420px' overflow='auto'
                                    px={{ base: '2px', md: '10px' }}
                                    spacing={4}
                                    align='stretch'
                                    mt='5px'>
                                    {
                                        scale && scale.map((ele, index) => {
                                            return (
                                                <Box key={index} bg='white' borderRadius='5px' p='10px'>
                                                    <Box pl='10px'>
                                                        <Heading as='h3' size='md'> {ele.display_name}</Heading>
                                                        {/* <Text>{ele.display_name}</Text> */}
                                                    </Box>
                                                    <Box ml='5px' mt='5px'>
                                                        <Box fontWeight='bold' fontSize='12px' borderRadius='2px' color='white' bg='teal' w='fit-content' px='10px'>{ele.name}</Box>
                                                    </Box>


                                                </Box>


                                            )
                                        })
                                    }
                                </VStack>
                            </Box>
                        </Flex>
                    </Box>
                    :
                    <LoadingComp />
                }
            </Box>
        </Box>
    )
}


export default Divisionsinfo