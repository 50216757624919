import React, { useState } from 'react';
import { TiExport } from "react-icons/ti";
import { Button } from '@chakra-ui/react';

const ExportBtn = ({ handelExport,exportLoad }) => {


    return (
        <Button
            isLoading={exportLoad}
            rightIcon={<TiExport fontSize='20px' />}
            borderRadius='3px'
            colorScheme='teal'
            onClick={handelExport}
        >Export</Button>
    )
}

export default ExportBtn