// Chakra imports
import { Avatar, Box, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export default function Banner(props) {
  const { banner, avatar, name, job} = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  return (
    <Box bg='white' w={{ base: '100%', md: '100%'}} mb={{ base: "0px", lg: "12px" }} align='center' shadow='base' borderRadius='10px' m='auto' p='10px'>
      <Box
        bg={`url(${banner})`}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        bgSize='cover'
        borderRadius='16px'
        h='200px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={avatar}
        h='100px'
        w='100px'
        mt='-23px'
        border='4px solid'
        name={name}
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {name}
      </Text>
      {/* <Text color={textColorSecondary} fontSize='sm'>
        {job}
      </Text> */}

    </Box>
  );
}
