// Chakra Imports
import {
	Avatar,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text, Box,
	useColorModeValue,
	Image, AvatarBadge
} from '@chakra-ui/react';
// Custom Components

import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { AuthContext } from 'layouts/auth/Authantication';
// Assets
import logo from 'assets/img/logo.png';
import routes from 'routes.js';
import { useNavigate, useLocation } from "react-router-dom";

export default function HeaderLinks(props) {
	const { secondary } = props;
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const { name } = JSON.parse(localStorage.getItem('user')) || [];
	const { logoutAuth } = useContext(AuthContext);
	const Navigate = useNavigate();


	return (
		<Flex 
			m='auto'
	
			alignItems="center"
			flexDirection="row"
			// flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			justifyContent='space-between'>

			<SidebarResponsive routes={routes} />
			<Box w='130px' display={{ base: 'block', xl: 'none' }} mb='10px'>
				<Image w='100%' src={logo} alt='kribado' />
			</Box>
			<Menu>
				<MenuButton >
					<Flex justifyContent='center' gap='10px' alignItems='center'>
						<Box  borderRadius='50%' p='2px' transition='0.5s' >
							<Avatar
								color="white"
								m='auto'
								name={name}
								size='md'
								w="40px"
								h="40px">
								<AvatarBadge boxSize='15px' bg='green.500' />
							</Avatar>
						</Box>
					</Flex>
				</MenuButton>
				<MenuList boxShadow='base' p="0px" borderRadius="5px" bg={'gray.100'} border="none" py='20px' >
					<Flex w="100%" mb="0px" bg='teal' borderTopRadius='5px'>
						<Text
							ps="20px"
							pt="16px"
							pb="10px"
							w="100%"
							borderBottom="1px solid"
							borderColor={borderColor}
							fontSize="sm"
							fontWeight="400"
							color="white">
							👋&nbsp; Hey, {name}
						</Text>
					</Flex>
					<Flex flexDirection="column" p="5px">
						<MenuItem
							_hover={{ bg: 'none' }}
							color="blue.400"
							borderRadius="8px"
							px="14px"
							onClick={() => Navigate('/admin/profile')}
						>
							<Text as='b' fontSize="sm">View Profile</Text>
						</MenuItem>
						<MenuItem
							_hover={{ bg: 'none' }}
							color="white"
							borderRadius="8px"
							px="14px" onClick={logoutAuth}>
							<Text as='b' fontSize="sm" bg='red.600' p='10px' borderRadius='5px' px='20px'>Log out</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
