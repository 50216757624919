import React from "react";

import { Icon, Box } from "@chakra-ui/react";
import {
  MdPerson,
} from "react-icons/md";
import { BsFillGridFill, BsClipboard2DataFill } from "react-icons/bs";
import { FiFile, FiClipboard,FiMapPin,FiUser } from "react-icons/fi";

import { IoIosPeople } from "react-icons/io";
import { BsFillDeviceSsdFill } from "react-icons/bs";
// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
// import DataTables from "views/admin/dataTables";


// Auth Imports

const routes = [
  {
    id: 1,
    name:  "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon width='28px' height='30px' as={BsFillGridFill} color='inherit' />,
    component: MainDashboard,
  },
  {
    id: 2,
    name: "Employee List",
    layout: "/admin",
    path: "/employee-list",
    icon: (
      <Box textAlign='center'>
        <Icon
          as={IoIosPeople}
          width='28px'
          height='30px'
          color='inherit'
        />
      </Box>
    )
  },
  {
    id: 3, // This id is now 3
    name: "Reports",
    layout: "/report",
    icon: <Icon as={BsClipboard2DataFill} width='28px' height='30px' color='inherit' />,
    path: "/reports",
    secondary: true,
    subrouts: [
      {
        id: 31, // Changed to 31
        name: "Camp Report",
        layout: "/report",
        path: "/camp-reports",
        icon: <Icon as={FiFile} width='20px' height='20px' color='inherit' />,

      },
      {
        id: 32, // Changed to 32
        name: "Test Report",
        layout: "/report",
        path: "/test-reports",
        icon: <Icon as={FiClipboard} width='20px' height='20px' color='inherit' />,

      },
      {
        id: 33, // Changed to 33
        name: "Regionwise Report",
        layout: "/report",
        path: "/regionwise-camp-reports",
        icon: <Icon as={FiMapPin} width='20px' height='20px' color='inherit' />,

      },
      {
        id: 34, // Changed to 34
        name: "Doctor List",
        layout: "/report",
        path: "/doctor-list",
        icon: <Icon as={FiFile} width='20px' height='20px' color='inherit' />,
      },
      {
        id: 35, // Changed to 35
        name: "Defaulter Report",
        layout: "/report",
        path: "/defaulter_report",
        icon: <Icon as={FiFile} width='20px' height='20px' color='inherit' />,
      },
      {
        id: 36, // Changed to 36
        name: "Employeewise Report",
        layout: "/report",
        path: "/employeewise_report",
        icon: <Icon as={FiFile} width='20px' height='20px' color='inherit' />,
      },
      {
        id: 37, // Changed to 37
        name: "HQwise Report",
        layout: "/report",
        path: "/hqwise-camp-reports",
        icon: <Icon as={FiMapPin} width='20px' height='20px' color='inherit' />,

      },
      {
        id: 38, // Changed to 38
        name: "Specialitywise Report",
        layout: "/report",
        path: "/specialitywise-camp-reports",
        icon: <Icon as={FiMapPin} width='20px' height='20px' color='inherit' />,

      },
    ]
  },
  {
    id: 4, // This id is now 4
    name: "Camp Plan",
    layout: "/camp_plan",
    icon: <Icon as={BsClipboard2DataFill} width='28px' height='30px' color='inherit' />,
    path: "/camp_plan",
    secondary: true,
    subrouts: [
      {
        id: 41, // Changed to 41
        name: "Planed Camps",
        layout: "/camp_plan",
        path: "/camp_plan_reports",
        icon: <Icon as={FiFile} width='20px' height='20px' color='inherit' />,

      },
      {
        id: 42, // Changed to 42
        name: "Executed Camps",
        layout: "/camp_plan",
        path: "/camp_plan_executed_report",
        icon: <Icon as={FiFile} width='20px' height='20px' color='inherit' />,

      },
      {
        id: 43, // Changed to 43
        name: "Not-Executed Camps",
        layout: "/camp_plan",
        path: "/camp_plan_not_executed_report",
        icon: <Icon as={FiFile} width='20px' height='20px' color='inherit' />,
      },
      {
        id: 44, // Changed to 43
        name: "Not-Planned Camps",
        layout: "/camp_plan",
        path: "/camp_not_plan",
        icon: <Icon as={FiFile} width='20px' height='20px' color='inherit' />,

      }
    ]
  },
  {
    id: 5, // This id remains the same
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='28px' height='30px' color='inherit' />,
    component: Profile,
  }
];




export default routes;
