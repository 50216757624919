// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
// Custom components
import ReactApexChart from "react-apexcharts";
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
// import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import React, { useEffect, useState } from "react";
import { top_regionwise_graphs_piechart } from "views/admin/request/admin_request";
import LoadingComp from "components/LodingComp/LoadingComp";


function toTitleCase(str) {
  if (!str || typeof str !== 'string' || str.trim() === '') {
      return '-';
  }
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export default function Conversion() {
  const [campData, setCampData] = useState([]);
  const [stateCodes, setStateCods] = useState([]);
  const [loading, setLoading] = useState([]);

  const getData = async () => {
    try {
      setLoading(true)
      let { data } = await top_regionwise_graphs_piechart();
      let camps = data.data.map(ele => ele.camps);
      let state_code = data.data.map(ele => toTitleCase(ele.state_code));
      // console.log({ camps, state_code })
      setCampData(camps);
      setStateCods(state_code);
      setLoading(false);
    } catch (err) {
      console.log({ Error: err })
    }
  }

  useEffect(() => {
    getData()
    // console.log({ campData, stateCodes })
  }, [])

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );


  let pieChartOptions = {
    labels: stateCodes,
    colors: [
      "#68D391", // green.200
      "#F56565", // red.200 (Lime)
      "#4FD1C5", // teal.200 (Magenta)
      "#F687B3", // pink.200 (Cyan)
      "#63B3ED", // blue.200 (OrangeRed)
      "#F6E05E", // yellow.200 (HotPink)
      "#D4D4D4",
      "#FF7F50", // Coral 
      "#00FF7F", // SpringGreen
      "#FFA500", // Orange
  ],
    chart: {
      type: 'donut',
      
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 1000,
        animateGradually: {
          enabled: true,
          delay: 150 
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500 
        }
      }
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: true,
      formatter: (seriesName, { seriesIndex }) => {
        return `${stateCodes[seriesIndex]} - ${campData[seriesIndex] || 0}`;
      },
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: false 
      }
    },
    dataLabels: {
      enabled: true,
    },
    hover: { mode: null },
    fill: {
      colors: [
        "#68D391", // green.200
        "#F56565", // red.200 (Lime)
        "#4FD1C5", // teal.200 (Magenta)
        "#F687B3", // pink.200 (Cyan)
        "#63B3ED", // blue.200 (OrangeRed)
        "#F6E05E", // yellow.200 (HotPink)
        "#D4D4D4",
        "#FF7F50", // Coral 
        "#00FF7F", // SpringGreen
        "#FFA500", // Orange
      ],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };


  return (
    <Box p="20px" bg="white" mt="40px"  borderRadius="md"  shadow='md'  >
      <Text mb="5px" fontWeight='bold' fontSize='20px' color='gray.600'>Top 10 Regions  <small>(Based on Camps of last 3 months)</small></Text>
      {
        loading ? <LoadingComp /> :
        <ReactApexChart options={pieChartOptions} series={campData} type="donut" />
      }
    </Box>
  );
}
