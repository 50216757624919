import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({children}) => {
    if(localStorage){
       let token = localStorage.getItem('token')||null;
       let user = localStorage.getItem('user')||null;

       if(token === undefined || token === null || token === '' || user === undefined || user === null ){
        return <Navigate to='/auth/sign-in'/>
       }
    }

    return children
}

export default PrivateRoute