import React, { useState } from 'react';
import { createContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const AuthContext = createContext()
const LOGIN_URL = process.env.REACT_APP_API_URL;
const DATA_API = process.env.REACT_APP_API_URL;

const Authantication = ({ children }) => {
  const [campplan , setCampplan] = useState(false);
  const Navigate = useNavigate();


  let loginAuth = async (obj) => {
    try {
      let { data } = await axios.post(`${LOGIN_URL}/login`,obj)
      let token = data.data.token;
      let user = data.data.user;
      
      localStorage.removeItem('token')
      localStorage.setItem('token', token);
      localStorage.removeItem('user')
      localStorage.setItem('user', JSON.stringify(user));
      return data.status

    } catch (err) {

      return err
    }
  
  }

  let logoutAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.clear();
    Navigate('/auth/sign-in')
  }

 const FetchDivision = async(id) => {
    try {
      let {data} = await axios.get(`${DATA_API}/division_detail/${id}`,
     { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token')}})
     const endCampBtn = data.data.meta.find(item => item.key === "CAMP_PLAN");

    // localStorage.removeItem('camp_plan');
     if(endCampBtn !==undefined){
    //  localStorage.setItem('camp_plan', endCampBtn?.value)
      setCampplan(endCampBtn?.value)
     }else{
      //localStorage.setItem('camp_plan', false)
      setCampplan(false)
     }
  //    console.log(endCampBtn.value)
     return data
  } catch (err) {
      return err
  }
  }


  return (
    <AuthContext.Provider value={{ loginAuth, logoutAuth, campplan, FetchDivision }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, Authantication };