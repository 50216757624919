import React, { useState } from 'react';
import {
    Box, Image, Flex, Input,
    FormLabel, InputGroup,
    Button, FormControl,
    Stack, useToast
} from '@chakra-ui/react';
import { ForgetPW } from '../request';
import { useNavigate } from "react-router-dom";
import logo from '../../../../assets/img/logo.png'
const Forgetpassword = () => {
    let [username, setUsername] = useState('')
    let toast = useToast();
    let Navigate = useNavigate();
    let [loading , setLoading] = useState(false);

    const handelAuth = async (event) => {
        event.preventDefault();
        setLoading(true);

 
        await ForgetPW(username)
            .then(res => {
                setLoading(false)
                if (res.status === 'We have emailed your password reset link.') {
                    // return
                    toast({
                        title: res.status,
                        status: 'success',
                        isClosable: true,
                        position: 'top'
                    });
                    Navigate(`/auth/sign-in`)
                } else {
                    toast({
                        title: 'Request failed.',
                        status: 'error',
                        isClosable: true,
                        position: 'top'
                    });
                    console.log({res})
                }
            })


    }

    return (
        <Flex bgGradient='linear(to-l, #26B0B1, #248F8F)' w='100%' pl={{ base: '0', md: '0', lg: '55px' }} h='100vh' justifyContent='center' alignItems='center' m='' gap='30px'>

            <Box w={{ base: '100%', md: '30%' }} bg='#FFFF' p='20px' pt='50px' h='fit-content' color='gray' fontSize='s' mt={{ base: '0', md: '0', lg: '30px' }} borderRadius='10px' boxShadow='md'>
                <Flex m='auto'
                    direction='column' w='50%' mb='70px'>
                    <Image src={logo} alt='logo' />
                </Flex>

                <Box pb='50px'>
                    <Stack spacing='32px'>
                        <form onSubmit={handelAuth}>
                            <FormControl isRequired>

                                <FormLabel as='samp'>Email</FormLabel>
                                <InputGroup size='md' mb='20px'>
                                    <Input
                                        isRequired={true}
                                        variant='auth'
                                        h='50px'
                                        type='email'
                                        name='username'
                                        bg='#F1F6F9'
                                        placeholder='Enter your EmailId'
                                        borderRadius='3px'
                                        border='2px solid white'
                                        color='black'
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </InputGroup>

                            </FormControl>
                            <Button
                                isLoading={loading}
                                h='50px'
                                colorScheme='teal'
                                w='100%' borderRadius='5px'
                                fontWeight='bold'
                                border='2px solid white'
                                type='submit'
                            >
                           {
                            loading ? 'loading ...' : 'Submit'
                           }
                            </Button>
                        </form>
                    </Stack>
                </Box>
            </Box>
        </Flex>
    )
}

export default Forgetpassword