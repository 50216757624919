import React from 'react'
import { Box, Text } from '@chakra-ui/react';
import { fetch_HQ_wise_reports, ExportExcel_HQreport } from '../request';
import ReportTable from '../reporttable_componunt/GeneralDataTable';

function toTitleCase(str) {
    if (!str || typeof str !== 'string' || str.trim() === '') {
        return '-';
    }
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
};

const HqWiseReports = () => {


    const column = [
        {
            name: <Text as='b'>HQ</Text>,
            selector: row => toTitleCase(row?.hq) || '-',
            sortable: true,
            sortField: 'hq',
        },
        {
            name: <Text as='b'>Region</Text>,
            selector: row => toTitleCase(row?.region) || '-',
            sortable: true,
            sortField: 'region',
        },
        {
            name: <Text as='b'>Camps</Text>,
            selector: row => row?.camps || '-',
            sortable: true,
            sortField: 'camps',
        },
        {
            name: <Text as='b'>Doctors</Text>,
            selector: row => row?.doctors || '-',
            sortable: true,
            sortField: 'doctors',
        },
        {
            name: <Text as='b'>Patients</Text>,
            selector: row => row?.patients || '-',
            sortable: true,
            sortField: 'patients',
        },
        {
            name: <Text as='b'>Tests</Text>,
            selector: row => row?.tests || '-',
            sortable: true,
            sortField: 'tests',
        },

    ];

    return (
        <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }}>


            <ReportTable
                header={'HQ-wise Reports'}
                column={column}
                fetchfun={fetch_HQ_wise_reports}
                exportFunction={ExportExcel_HQreport}
                exportConsent={null}
                countlabel={"HQ's"}
                sortablecolm='hq'
                showscale={false}
            />

        </Box>
    )
}

export default HqWiseReports